<template>
    <div class="container-fluid">
        <div class="container my-5 py-5">
            <div class="text-center">
                <h1 class="text-blue mb-3">FREE ONLINE XERO WORKSHOP</h1>
                <h5 class="text-darkblue fw-light">Online accounting software for all business</h5>
            </div>
            <div class="row gx-5 mt-5 pt-5">
                <div class="col-7">
                    <p class="description ps-4">
                        Xero has the popular features that will change your life. An online accounting software with all the time-saving tools you need to grow your business – unlimited users and 24/7 support. Not to mention the security and reliability you’d expect from Xero.
                    </p>
                    <p class="mt-5 fst-italic">
                        If you would like a free Xero demo, please contact us<br/>
                        089 793 8185
                    </p>
                </div>
                <div class="col-5">
                    <h3 class="mb-4">Workshop Coverage</h3>
                    <div v-for="(coverage, index) in coverages" :key="index" class="card coverage my-3">
                        <div class="card-body p-4">
                            <h5 class="mb-0 fw-light">{{ coverage }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.description {
    border-left: 4px solid var(--navy);
}
.coverage {
    border-color: var(--navy);
    border-left-width: 8px;
}
</style>
<script>
export default {
    data() {
        return {
            coverages: [
                "Introduction to XERO",
                "How to set up your Accounts in XERO",
                "Set-up Chart of Accounts",
                "Setup tracking categories",
                "Setup bank accounts",
                "Customise Invoice"
            ]
        }
    }
}
</script>